import React from "react";
import Seo from "../components/molecules/Seo";

import styled from 'styled-components';
import {graphql, Link} from "gatsby";
import Newsletter from "../components/organisms/Newsletter";
import Header from "../components/molecules/Header";
import Container from "../components/atoms/Container";
import HrHeading from "../components/atoms/HrHeading";
import NewsletterCard from "../components/molecules/NewsletterCard";
import ColorScheme from "../components/atoms/ColorScheme";
import PrismicRichText from "../components/atoms/PrismicRichText";




const Intro = styled.div`
  text-align: center;
  margin: 2rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const ArchiveLink = styled.p`
  text-align: right;
  color: ${props => props.theme.colours.grey};
  a {
    font-style: italic;
  }
`;


const IndexPage = ({data}) => {
  const newsletters = data.allPrismicNewsletter;

  return (
    <ColorScheme primary={newsletters.nodes[0].data.primary} secondary={newsletters.nodes[0].data.secondary}>
      <Seo
        title="Home"
        image={newsletters.nodes[0].data.og_image && newsletters.nodes[0].data.og_image.url} />
      <Header title="A Newsletter"/>
      <Container>
        <Intro className="mono large">
          <PrismicRichText render={data.prismicGlobalSettings.data.homepage_intro_text.raw}/>
        </Intro>
      </Container>
      <HrHeading>
        <h2 className="h3">Latest issue: {newsletters.nodes[0].data.edition.text}</h2>
      </HrHeading>
      <Newsletter newsletter={newsletters.nodes[0]} />
      <HrHeading>
        <h2 className="h3">Back issues:</h2>
      </HrHeading>
      <Container>
        {newsletters && newsletters.nodes.map(newsletter => <NewsletterCard key={newsletter.uid} newsletter={newsletter} />)}
        <ArchiveLink className="mono small"><Link to="/archive">View all back issues</Link></ArchiveLink>
      </Container>
    </ColorScheme>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query MyQuery {
    allPrismicNewsletter(sort: {order: DESC, fields: first_publication_date}) {
      nodes {
        uid
        data {
          primary
          secondary
          og_image {
            url(imgixParams: {height: 630, width: 1200})
          }
          og_description {
            text
          }
          date {
            text
          }
          edition {
            text
          }
          conclusion {
            raw
          }
          body {
            ... on PrismicNewsletterDataBodySection {
              id
              items {
                link {
                  document {
                    ... on PrismicLink {
                      id
                      tags
                      data {
                        title {
                          text
                        }
                        text {
                          raw
                        }
                        link {
                          url
                        }
                        layout
                        image {
                          alt
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        author {
                          text
                        }
                      }
                    }
                  }
                }
              }
              primary {
                curator1 {
                  document {
                    ... on PrismicContributor {
                      id
                      data {
                        name {
                          text
                        }
                      }
                      url
                    }
                  }
                }
                section_title {
                  text
                }
                introduction {
                  raw
                }
              }
            }
          }
        }
      }
    }
    prismicGlobalSettings {
      data {
        homepage_intro_text {
          raw
        }
      }
    }
  }
`