import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Container from "./Container";

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    background-color: ${props => props.secondary ? props.theme.secondary : props.theme.primary};
    z-index: 1;
  }

  > h1, h2, h3, h4, h5, h6, p {
    background-color: ${props => props.theme.colours.white};
    padding: 0 1rem;
    z-index: 2;
    position: relative;
  }
`;

function HrHeading({children, secondary}) {
  return (
    <Container>
      <Holder secondary={secondary}>
        {children}
      </Holder>
    </Container>
  )
}

HrHeading.propTypes = {
  secondary: PropTypes.bool,
};

HrHeading.defaultProps = {
  secondary: false,
};

export default HrHeading;