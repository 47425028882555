import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {Link} from "gatsby";

const Holder = styled.div`
  a {
    text-decoration: none;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    grid-gap: 0.5rem;
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      align-content: center;
    }
    &:hover {
      text-decoration: underline;
    }
    p {
      @media( ${props => props.theme.breakpoints.md} ) {
        justify-self: end;
      }
    }
    h3, p {
      margin: 0;
    }
  }
`;

function NewsletterCard({newsletter}) {
  const {edition, date, body} = newsletter.data;
  const uid = newsletter.uid;
  const {curator1} = body[0].primary;
  return (
    <Holder>
      <Link to={`/newsletter/${uid}`}>
        <h3>{edition.text}</h3>
        <p className="mono">{curator1.document.data.name.text} &bull; {date.text} &rarr;</p>
      </Link>
    </Holder>
  )
}

NewsletterCard.propTypes = {
  newsletter: PropTypes.object.isRequired,
};

export default NewsletterCard;